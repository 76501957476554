.learning-component-class{
  position: relative;
  margin-top: 1%;
  min-height: 80vh;
}

.learning-component-textbox-class {
  position: absolute;
  font-size: small;
  border: 2px solid black;
  padding: 0.3em 1em;
  max-width: 11em;
  background-color: white;
  z-index: 2;
}

.learning-component-image-class {
  position: absolute;

  top: 100px;
  left: 250px;
  max-width: 350px;
}

.learning-component-line {
  position: absolute;

  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  stroke: rgba(21, 42, 87, 0.808);
  z-index: 1;
}
.learning-component-outline {
  position: absolute;
  fill: none;
  stroke-width: 1;
  stroke-linecap: round;
  stroke: rgba(162, 201, 228, 0.87);
}

.learning-component-debug {
  stroke: rgba(0, 255, 0, 0.541);
  z-index: 10;
  stroke-width: 1;
}