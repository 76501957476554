
.users_able_container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.users_table {
    height: 100%;
    width: 100%;
    font-size: 1vw;

    padding: 10px;
    background-color: rgb(157, 206, 250);
    border: 2px solid black;
    border-radius: 6px;
    margin-bottom: 2%;
    
}

.users_table tr {
    height: 20px;
    width: 100px;

    border-collapse: separate;
    border: 2px solid rgb(177, 210, 245);
    border-radius: 6px;

    background-color: rgb(253, 255, 255);
    
}
