.ld_page_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;

    border-radius: 10px;
    border: 2px solid rgb(92, 195, 230);
    padding-top: 1%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 7%;
    background-color: rgb(235, 247, 252);
    
}

.ld_table_container {
    width: 60%;
    height: 20%;


    border: 2px solid rgb(177, 210, 245);
    border-radius: 6px;

    background-color: rgb(253, 255, 255);
}

.ld_table {
    height: 100%;
    width: 100%;

    border: none;

    border-collapse: collapse;
}

.ld_table td, .ld_table tr {
    padding: 20px;
    border-style: none;
    text-align: center;
}

.ld_table th {
    border: none;
    border-radius: 6px;
    padding: 10px;
}


.ld_table tr:nth-child(even) {
    background-color: rgb(236, 249, 253);
}

.ld_table_fields tr:hover {
    background-color: #B5E8FF !important; 
}

.table_title {

    margin-top: 2%;
    margin-bottom: 2%;
    text-align: center;
    background-color: rgb(206, 243, 255);
    border: 2px solid rgb(44, 91, 138);

    border-radius: 6px; 
}
