.container {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;

  border-radius: 10px;
  border: 2px solid rgb(92, 195, 230);
  padding-top: 1%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 1%;
  background-color: rgb(235, 247, 252);
}

.quiz-question {
  margin-left: 14px;
  width: 50%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 10px;
  background-color: rgb(217, 238, 247);
}

.quiz-choice-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
}

.quiz-choice {
  list-style: none;
  text-align: center;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.quiz-choice-text {
  margin-left: 50px;
}

#Question1 img {
  object-fit: contain;

  max-width: 500px;
  max-height: 500px;
}

.quiz-choice img {
  object-fit: contain;

  max-width: 250px;
  max-height: 250px;
}

.quiz-choice-selected {
  background-color: rgb(217, 238, 247);
}

.quiz-question-text {
  margin-top: 80px;
}

.text-choice {
  width: 50vw;
  text-align: start;
}