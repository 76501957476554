.blog {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;

  border-radius: 10px;
  border: 2px solid rgb(92, 195, 230);
  padding-top: 1%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 1%;
  background-color: rgb(235, 247, 252);
}

.new_post_form {
  border: 2px solid rgb(119, 145, 194);
  border-radius: 6px;
  text-align: center;
  margin: 1% auto;
  padding: 1%;
  width: 60%;

  background-color: rgb(193, 213, 243);
}

.new_post_form > form {
  display: inline-block;
}

.new_post_form > form > label > textarea {
  width: 100%;
  resize: vertical;
  min-height: 100px;
  max-height: 400px;
  background-color: rgb(243, 249, 252);
}

.new_post_form > form > label > input {
  width: 60%;
}

.post {
  display: flex;
  flex-direction: column;

  border: 2px solid rgb(109, 144, 207);
  border-radius: 6px;
  background-color: rgb(196, 217, 255);

  margin: 5% auto;
  padding: 1%;
  width: 60%;
}

.post > button {
  margin: 1%;
}

.title {
  text-align: start;
}

.title > div {
  word-wrap: break-word;
}

.body {
  margin: 1%;
  border: 1px solid rgb(140, 134, 196);
  background-color: rgb(243, 249, 252);
  padding: 5px;
}

.body > div {
  word-wrap: break-word;
}

.comment {
  margin: 1%;
  border: 2px solid black;
  padding: 1%;
}

.blog_bottom_display {
  display: grid;
  grid-template-columns: 100px 100px auto 80px;
  width: 100%;

  text-align: center;
}

.blog_bottom_display button {
  width: 100%;
  word-wrap: break-word;
}

