.module_icon_container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  margin-left: 5%;
  margin-right: 5%;
}

.module_icon {
  height: 200px;
  width: 160px;
}

.page_content {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;

  border-radius: 10px;
  border: 2px solid rgb(92, 195, 230);
  padding-top: 1%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 1%;
  background-color: rgb(235, 247, 252);
}