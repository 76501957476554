.home-class {
  margin: 5vh;
  padding: 2vh;
  max-height: 80vh;
}

.home-module-column {
  position: relative;
  display: table-cell;
  width: 18%;
  max-width: 18%;
  height: 60%;
  max-height: 60%;
  border-radius: 0.5% 10%;
  padding: 1%;

  background-color: rgb(207, 207, 207);
  box-shadow:
    inset 0 0 10px white,
    10px 15px 5px rgba(26, 26, 26, 0.5);
  margin: 10px;
}

.home-module-container {
  display: table;
  width: 85%; 
  max-width: 85%;
  height: 70vh;
  margin-left: auto;
  margin-right: auto;
  border-spacing: 1.5vw;
  box-sizing: border-box;
}

.home-module-header-container {
  max-width: 80%;
  width: 80%;
  height: 6.5em;
  max-height: 6.5em;
  clear: top;

  position: relative;
  top: -10%;
  left: 10%;

  background-color: white;

  border-radius: 15% / 50%;

  /* ensure header is above image */
  z-index: 2;

  /* font */
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: large;

  box-shadow:
    rgb(12, 12, 12) 6px 6px 1px,
    rgb(12, 12, 12) -6px 6px 1px,
    rgb(12, 12, 12) -6px -6px 1px,
    rgb(12, 12, 12) 6px -6px 1px;
}

.home-module-header-text {
  top: 50%;
  position: absolute;
  text-align: center;
  max-height: 6.5em;
  width: 100%;

  font-size: 1vw;
  
  transform: translate(0, -50%);
}

.home-module-header-text a {
  text-decoration: none;
}

.home-module-image {
  position: absolute;
  top: 5%;
  left: 5%;
  
  max-width: 90%;
  max-height: 90%;

/* black border of 3 pixels*/
  box-shadow:
  -3px 3px 0px black,
  3px 3px 0px black,
  -3px -3px 0px black,
  3px -3px 0px black;

  z-index: 1;
}
