.questionnaire {
  margin-left: 15%;
  margin-right: 15%;
  margin-bottom: 10%;

  border-radius: 10px;
  border: 2px solid rgb(92, 195, 230);
  padding-top: 1%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 1%;
  background-color: rgb(235, 247, 252);
}

.questionnaire_choice_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
}

.question {
  margin-top: 80px;
}

.questionnaire_choice {
  list-style: none;
  text-align: center;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.questionnaire_q_image {
  display: block;
  margin: auto;

  object-fit: contain;

  max-width: 500px;
  max-height: 500px;
}

.questionnaire_choice img {
  object-fit: contain;

  max-width: 250px;
  max-height: 250px;
}

.response_text {
  resize: vertical;

  width: 94%;
  height: 70px;
  min-height: 50px;
  max-height: 150px;
}

.remaining_char_display {
  font-size: 12px;
}

ul.agree_disagree_choices {
  list-style:none;
}

.question {
  margin-bottom: 20px;
}

.submit_button {
  margin-left: 20px;
}
