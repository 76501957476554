.slide_video_wrapper {
    position: relative;
    padding-bottom: 56.25%;
}

.slide_video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.slide_body{
    margin-left: 0%;
    margin-right: 0%;
}

.disable_popout {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    right: 0;
}