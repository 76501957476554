.slideshow_body {
    display:grid;
    grid-template-columns: auto 20%;
    grid-template-rows: auto auto 10%;

    background-color: rgb(193, 223, 236);
    border-style: none;
    border-radius: 10px;
    border: 2px solid rgb(163, 208, 228);
}

.title_content {
    text-align: start;
    font-size: 15px;
}

.slide_content {
    background-color: rgb(109, 144, 207);
}

.slide_counter {
    text-align: center;
    margin: auto;
}

.navigation {
    display: grid;
    background-color: rgb(109, 144, 207);
}

.navigation div button {
    height: 100%;
    width: 100%;
    font-size: 2vw;
    background-color: rgb(144, 171, 212);

    border-style: none;
    border-radius: 10px;
    border: 2px solid rgb(128, 150, 209);
}

.navigation div button:hover {
    cursor: pointer;
}

.nav_icon {
    height: 100%;
    width: 100%;
}

.navigation div button:hover {
    background-color: rgb(163, 188, 224);
}