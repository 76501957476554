
.ibd-page-grid {
    display: grid;
    grid-template-columns: 40% 59.5%;
    column-gap: 5px;

    background-color: rgb(235, 247, 252);
    margin-left: 2.5%;
    margin-right: 2.5%;
    margin-bottom: 5%;

    border-radius: 10px;
    border: 2px solid rgb(92, 195, 230);
}

.cell-chart-title {
    padding: 10px;
    font-size: 30px;
    text-align: center;

    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    justify-self: center;
}

.ibd_page_back{
    text-align: center;
}

.cell-info-title {
    padding: 10px;
    font-size: 20px;
    text-align: center;
    background-color: rgb(109, 144, 207);
    

    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    align-self: end;
}

.cell-info-content {
    padding: 20px;
    font-size: 16px;
    text-align: center;
    background-color: rgb(109, 144, 207);

    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    align-self: stretch;
}

.cell-info-content img {
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
    border: 2px solid rgb(127, 158, 216);
}

.cell-body-chart {
    font-size: 30px;
    text-align: center;

    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    
    background-color: rgb(193, 223, 236);
    border-style: none;
    border-radius: 10px;
    border: 2px solid rgb(163, 208, 228);
}

.body-part > *:hover > * {
    filter: url('#clickableHighlight');
    cursor: pointer;
}