.logo {
    display: block;
    height: 30vmin;
    width: 30vmin;
    margin: auto;

}

.login_form {
    display: block;
    text-align: center;
    margin: auto;

    font-size: 2vmin;
}

.login_input {
    background-color: rgb(232, 248, 255);
    border: 2px solid rgb(32, 89, 112);
    border-radius: 6px;
    padding: 3px;

    font-size: 2vmin;
    
    margin: 2px;
}

.login_button {
    margin: 10px;
}

.login_error {
    color: red;
}

.welcome_message {
    background-color: rgb(206, 243, 255);
    border-radius: 10px;
    border: 2px solid rgb(124, 206, 233);
    padding-top: 1%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 1%;

    font-size: 2vmin;
    padding: 2%;

    margin-left: 5%;
    margin-right: 5%;
}

.welcome_message h2 {
    text-align: center;
}