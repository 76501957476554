.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* black circle background pattern*/
.bg-class {
  position: fixed;
  left: -1px;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: -100;
  background-image: linear-gradient(120deg, #8eb9ff 0%, #cdfcff 100%);
  background-size:  100vw 110vh;
}