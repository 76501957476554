body {
    background-color: rgb(255, 255, 255);
}

.navbar {
    position: fixed;
    background-color: rgb(76, 90, 104);
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
    z-index: 100;
}

.navbar__logo {
    height: 100%;
    width: 100%;
}

.navbar__logo img {
    margin-top: 4px;
    height: 50px;
    width: 50px;
}

.navbar__block {
    display: flex;
    height: 100%;
    align-items: center;
    gap: 20px;
    padding: 0 1rem;
}
.navbar__block a {
    color: white;
    text-decoration: none;
}
.navbar__block a:hover {
    color:skyblue;
}

.navbar__items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}
.navbar__items li {
    padding: 10px;
    text-align: center;
}

.spacer {
    flex: 1;
}