.module_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin: 5% 10% 0% 10%;
  border: 5px solid black;
  border-radius: 45px;
}

.learningcomponent_links {
  display: block;
  margin: auto;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-left: 0px;
}

.links {
  margin: 20px;
  text-align: center;
}

.links a {
  text-decoration: none;
  color: black;

}

.links li {
  height: 30px;
  
  background-color: white;
  border: 5px solid black;
  border-radius: 45px;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 30px;
  margin-top: 5%;
}

.links img {
  border: 3px solid black;
  border-radius: 4px;
  
  display:block;
  margin:auto;

  height: 75%;
  width: 85%;
}

@media only screen and (max-width: 695px) {
  .module_title {
    font-size: 12px;

    margin: 5% 5% 0% 5%;
    border: 3px solid black;
    border-radius: 45px;
  }

  .links li {
    height: 40px;
    font-size: 12px;
    padding: 5px;
    border: 3px solid black;
  }

  .links img {
    border: 2px solid black;
  }
}

@media only screen and (min-width: 1200px) {
  .learningcomponent_links {

    margin-right: 15%;
    margin-left: 15%;
  }

  .links li {
    height: 40px;
    font-size: 20px;

  }
}
