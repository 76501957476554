.admin_panel {
    display: flex;
    flex-direction: column;

    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 5%;

    border-radius: 10px;
    border: 2px solid rgb(92, 195, 230);
    padding-top: 1%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 1%;
    background-color: rgb(235, 247, 252);

}