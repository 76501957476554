
.create_user_container {
    height: 100%;
    width: 80%;

    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 10px;

    padding: 20px;
    background-color: rgb(157, 206, 250);

    margin-bottom: 2%;
}

.create_user_container input {
    width: 80%;
}