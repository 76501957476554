.page_content {
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5%;

    border-radius: 10px;
    border: 2px solid rgb(92, 195, 230);
    padding-top: 1%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 1%;
    background-color: rgb(235, 247, 252);

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.diary_question {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 30px;
}

.diary_question_image {
    object-fit: contain;
    max-height: 400px;
    max-width: 400px;
}

.diary_question_choice {
    align-self: center;
    list-style: none;
}

.diary_submit_button {
    align-self: flex-end;
    width: 150px;
}